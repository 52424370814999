import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from 'react-bootstrap/Popover'
import Button from "react-bootstrap/Button";


const Box5 = () => (
    <p>EU, EWR (Europäischer Wirtschaftsraum) inkl. Schweiz und Israel</p>
);

const Box6 = () => (
    <p>Max. 6 Monate</p>
);
const Box7 = () => (
    <p>In den ersten beiden Vertragsjahren übernehmen wir Be&shy;handlungs&shy;kosten nur bis zu bestimmten Höchstsummen. Die jeweilige Leistungsbegrenzung be&shy;zieht sich auf alle Leistungen im gesamten Versicherungsjahr. Für Leistungen, die darüber hinaus&shy;gehen, müssen Sie in den ersten beiden Ver&shy;sicherungs&shy;jahren selbst auf&shy;kommen. Nach dem 24. Monat ent&shy;fällt die Leistungs&shy;begrenzung.</p>
);
function renderPopover(tooltipText) {return (<Popover>
    <Popover.Body>
        {tooltipText}
    </Popover.Body>
</Popover>)};
function ScopeCoverage() {
    return (
        <Accordion flush className="accordion-flush">
            <Accordion.Item eventKey="0">
                <div className="center"><Accordion.Header style={{padding: 0}}>Geltungsbereich</Accordion.Header></div>
                <Accordion.Body>
                    <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-10 ">
                            <label
                                className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                <p><span className="step-box-text" >Europa</span>
                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box5/>)}>
                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                    </OverlayTrigger>
                                </p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                <p ><i className="fa fa-check"></i>
                                </p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                <p><i className="fa fa-check"></i>
                                </p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                <p><i className="fa fa-check"></i>
                                </p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                <p><i className="fa fa-check"></i>
                                </p>
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-10 ">
                            <label
                                className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                <p><span className="step-box-text" >Außerhalb Europa</span>
                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box6/>)}>
                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                    </OverlayTrigger>
                                </p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                <p ><i className="fa fa-check"></i>
                                </p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                <p><i className="fa fa-check"></i>
                                </p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                <p><i className="fa fa-check"></i>
                                </p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                <p><i className="fa fa-check"></i>
                                </p>
                            </label>
                        </div>
                    </div>

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <div className="center"><Accordion.Header style={{padding: 0}}>Jahreshöchstleistung</Accordion.Header></div>
                <Accordion.Body>
                    <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-10 ">
                            <label
                                className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                <p><span className="step-box-text" >Höchstsummen</span>
                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box7/>)}>
                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                    </OverlayTrigger>
                                </p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                <p >3.000 € </p>
                                 <p>(in den ersten 24 Monaten)</p>
                                <p>unbegrenzt</p>
                                <p>(ab dem 25. Monat)</p>
                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                <p>700 €</p>
                                <p>(in den ersten 12 Monaten)</p>
                                <p>1.400 €</p>
                                <p>(in den ersten 24 Monaten)</p>
                                <p>unbegrenzt</p>
                                <p>(ab dem 25. Monat)</p>

                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                <p>1000 €</p>
                                <p>(in den ersten 12 Monaten)</p>
                                <p>2.000 €</p>
                                <p>(in den ersten 24 Monaten)</p>
                                <p>unbegrenzt</p>
                                <p>(ab dem 25. Monat)</p>

                            </label>
                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-10">
                            <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                <p>1.300 €</p>
                                <p>(in den ersten 12 Monaten)</p>
                                <p>2.600 €</p>
                                <p>(in den ersten 24 Monaten)</p>
                                <p>unbegrenzt</p>
                                <p>(ab dem 25. Monat)</p>



                            </label>
                        </div>
                    </div>


                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default ScopeCoverage;