//import { motion } from "framer-motion";

import {within} from "@testing-library/react";
import {forEach} from "react-bootstrap/ElementChildren";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import AllCollapseExample from "./Accordeon1";

const SignUp2 = ({formData, setFormData, page, setPage, x, setX}) => {
    const Box1 = () => (
        <ul className="disc-list">
            <li>Operationen</li>
            <li>Notfallversorgung (bis zum 4-fachen Satz der GOT)</li>
            <li>ausschließlich schmerzstillende Zahnbehandlungen inkl. Extraktionen</li>
        </ul>
    );

    const Box2 = () => (
        <ul className="disc-list">
            <li>Aufgrund eines abgebrochenen Zahnes</li>
            <li>Fremdkörper zwischen den Zähnen</li>
        </ul>
    );

    const Box3 = () => (
        <ul className="disc-list">
            <p>bis 10 Tage vor dem Eingriff</p>
        </ul>
    );

    const Box4 = () => (
        <ul className="disc-list">
            <p>bis 30 Tage nach dem Eingriff</p>
        </ul>
    );

    const Box5 = () => (
        <ul className="disc-list">
            Die Gesundheitspauschale wird jährlich ausgezahlt und dient für veterinärmedizinische Leistungen:<ul><li>Gesundheitscheck</li><li>Schutzimpfungen</li><li>Wurmkur</li><li>Floh- und Zeckenvorsorge</li><li>Zahnprophylaxe</li><li>Kastration oder Sterilisation</li><li>(chemische) Kastration</li><li>Krallen kürzen</li></ul>
        </ul>
    );

    const Box61 = () => (
        <p>EU, EWR (Europäischer Wirtschaftsraum) inkl. Schweiz und Israel</p>
    );

    const Box71 = () => (
        <p>Max. 6 Monate</p>
    );

    const Box81 = () => (
        <p>In den ersten beiden Vertragsjahren übernehmen wir Be&shy;handlungs&shy;kosten nur bis zu bestimmten Höchstsummen. Die jeweilige Leistungsbegrenzung be&shy;zieht sich auf alle Leistungen im gesamten Versicherungsjahr. Für Leistungen, die darüber hinaus&shy;gehen, müssen Sie in den ersten beiden Ver&shy;sicherungs&shy;jahren selbst auf&shy;kommen. Nach dem 24. Monat ent&shy;fällt die Leistungs&shy;begrenzung.</p>
    );

    function renderPopover(tooltipText) {return (<Popover>
        <Popover.Body>
            {tooltipText}
        </Popover.Body>
    </Popover>)};
    const handleChoice = function (e, choice) {
        console.log(choice)
        //set choice
        setFormData({...formData, service_name: choice});

        //deactivate previous active panels
        let panels = document.getElementsByClassName('step-box-content');
        for (const p of panels) {
            p.classList.remove('active')
        }

        //set active panel choice
        let panel = e.target.parentElement.parentElement;
        panel.classList.add("active");
    };

    const routeChange = (e, choice) =>{
        console.log('choice: ' + choice);
        let url =  "https://rosinas-finest.de/staging", location = "";

        console.log(process.env);
        switch(choice) {
            case 'choice1':
                location = "/versicherungen/hund-op/";
                break;
            case 'choice2':
                location = "/versicherungen/hund-voll-60/";
                break;
            case 'choice3':
                location = "/versicherungen/hund-voll-80/";
                break;
            case 'choice4':
                location = "/versicherungen/hund-voll-100/";
                break;
            default:
                location = "/versicherungen/hund-voll-100/";
        }
        location = url + location;
        console.log(location);
        window.location.href = location;
    }

    return (
        <div className=" js-active" data-animation="slideHorz">
            {/* div 1 */}
            <div className="wizard-forms">
                <div className="inner pb-100 clearfix">
                    <div className="form-content pera-content" style={{paddingTop: '0px'}}>
                        <div className="step-inner-content">

                            <h2>Unsere Hundekrankenversicherung</h2>
                            <p>
                                als OP-Versicherung oder Vollversicherung
                            </p>
                            <div className="step-box block-plan">
                                <div className="row" style={{alignItems: 'flex-end'}}>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label className="step-box-content bg-white text-center relative-position">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                                                    <img src={require("../assets/img/d2.png")} alt=""/>
                                                </span>*/}
                                            <p style={{borderBottomStyle: 'solid'}}><span className="step-box-text">Vollschutz<br/>60</span></p>
                                            <p style={{borderBottomStyle: 'solid'}}>

                                                <span style={{fontSize: '28px'}}><strong>60 %</strong></span>
                                                Kostenerstattung für Krankheit und Operationen
                                            </p>

                                            <p>
                                                <img src={require("../assets/img/csm_FV_Logo_Blue_2807fab66b.png")}/>
                                                <br></br>
                                                Videosprechstunde
                                            </p>
                                            <br></br>
                                            <p>
                                                    <span style={{fontSize: "28px"}}>
                                                        <strong>60 €</strong>
                                                    </span>jährliche Gesundheitspauschale<br></br>
                                            </p>
                                            <br/><br/>

                                            <span className="step-box-text">ab 49,90 €</span>
                                            <span className="service-check-option">
                             <span onClick={(event) => handleChoice(event, 'choice2')}>
                             </span>
                          </span>
                                        </label>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label className="step-box-content bg-white text-center relative-position">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                                                    <img src={require("../assets/img/d2.png")} alt=""/>
                                                </span>*/}
                                            <p style={{borderBottomStyle: 'solid'}}><span className="step-box-text">Vollschutz<br/>80</span></p>
                                            <p style={{borderBottomStyle: 'solid'}}>

                                                <span style={{fontSize: '28px'}}><strong>80 %</strong></span>
                                                Kostenerstattung für Krankheit und Operationen
                                            </p>

                                            <p>
                                                <img src={require("../assets/img/csm_FV_Logo_Blue_2807fab66b.png")}/>
                                                <br></br>
                                                Videosprechstunde
                                            </p>
                                            <br></br>
                                            <p>
                                                    <span style={{fontSize: "28px"}}>
                                                        <strong>80 €</strong>
                                                    </span>jährliche Gesundheitspauschale<br></br>
                                            </p>
                                            <br/><br/>

                                            <span className="step-box-text">ab 69,90 €</span>
                                            <span className="service-check-option">
                             <span onClick={(event) => handleChoice(event, 'choice3')}>
                             </span>
                          </span>
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <h2>Hundekrankenversicherung</h2>
                            <p>
                                Leistungen im Überblick
                            </p>

                            <div className="step-box block-plan">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-10 displayRates">
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10" >
                                        <label className="step-box-content bg-white text-center relative-position"
                                               style={{padding: 0, minHeight: 0}}>
                                            OP-Schutz 100
                                            <div className="displayBoxRates" style={{margin: '10px'}}>
                                                <p>Veterinärmedizinisch notwendige chirurgische Eingriffe 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box1/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Ausschließlich schmerzstillende Zahnbehandlungen inkl. Extraktion unter Narkose 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box2/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Operationsvorbereitende Untersuchungen 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box3/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Nachsorge nach dem chirurgischen Eingriff 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Unterbringung in einer Tierklinik oder Tierarztpraxis 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Arznei-, Verband-, Heil- und Hilfsmittel 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>
                                                    Geltungsbereich:<br/>
                                                    <ul>
                                                        <li>Europa
                                                            <OverlayTrigger placement="top" overlay={renderPopover(<Box61/>)}>
                                                                <Button className="border-dark btn-light btn-circle" >i</Button>
                                                            </OverlayTrigger>
                                                        </li>
                                                        <li>Außerhalb Europas
                                                            <OverlayTrigger placement="top" overlay={renderPopover(<Box71/>)}>
                                                                <Button className="border-dark btn-light btn-circle" >i</Button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    H&ouml;chstsummen:
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box81/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                    <ul>
                                                        <li>3.000 €
                                                            (in den ersten 24 Monaten)
                                                        </li>
                                                        <li>unbegrenzt
                                                            (ab dem 25. Monat)
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10"  style={{marginBottom: '20px'}}>
                                        <label className="step-box-content bg-white text-center relative-position"
                                               style={{padding: 0, minHeight: 0}}>
                                            Vollschutz 60
                                            <div className="displayBoxRates">
                                                <p>Veterinärmedizinisch notwendige chirurgische Eingriffe 60%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box1/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Veterinärmedizinisch notwendige Heilbehandlungen 60%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box1/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Ausschließlich schmerzstillende Zahnbehandlungen inkl. Extraktion unter Narkose 60%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box2/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Operationsvorbereitende Untersuchungen 60%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box3/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Nachsorge nach dem chirurgischen Eingriff 60%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Unterbringung in einer Tierklinik oder Tierarztpraxis 60%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Arznei-, Verband-, Heil- und Hilfsmittel 60%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Gesundheitspauschale 60 €</p>
                                                <p>
                                                    Geltungsbereich:<br/>
                                                    <ul>
                                                        <li>Europa
                                                            <OverlayTrigger placement="top" overlay={renderPopover(<Box61/>)}>
                                                                <Button className="border-dark btn-light btn-circle" >i</Button>
                                                            </OverlayTrigger>
                                                        </li>
                                                        <li>Außerhalb Europas
                                                            <OverlayTrigger placement="top" overlay={renderPopover(<Box71/>)}>
                                                                <Button className="border-dark btn-light btn-circle" >i</Button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    Höchstsummen:
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box81/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                    <ul>
                                                        <li>700 €
                                                            (in den ersten 12 Monaten)
                                                        </li>
                                                        <li>1.400 €
                                                            (in den ersten 24 Monaten)
                                                        </li>
                                                        <li>unbegrenzt
                                                            (ab dem 25. Monat)
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>

                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10" >
                                        <label className="step-box-content bg-white text-center relative-position"
                                               style={{padding: 0, minHeight: 0}}>
                                            Vollschutz 80
                                            <div className="displayBoxRates">

                                                <p>Veterinärmedizinisch notwendige chirurgische Eingriffe 80%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box1/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Veterinärmedizinisch notwendige Heilbehandlungen 80%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box1/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Ausschließlich schmerzstillende Zahnbehandlungen inkl. Extraktion unter Narkose 80%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box2/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Operationsvorbereitende Untersuchungen 80%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box3/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Nachsorge nach dem chirurgischen Eingriff 80%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Unterbringung in einer Tierklinik oder Tierarztpraxis 80%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Arznei-, Verband-, Heil- und Hilfsmittel 80%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Gesundheitspauschale 80 €</p>
                                                <p>
                                                    Geltungsbereich:<br/>
                                                    <ul>
                                                        <li>Europa
                                                            <OverlayTrigger placement="top" overlay={renderPopover(<Box61/>)}>
                                                                <Button className="border-dark btn-light btn-circle" >i</Button>
                                                            </OverlayTrigger>
                                                        </li>
                                                        <li>Außerhalb Europas
                                                            <OverlayTrigger placement="top" overlay={renderPopover(<Box71/>)}>
                                                                <Button className="border-dark btn-light btn-circle" >i</Button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    Höchstsummen:
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box81/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                    <ul>
                                                        <li>1.000 €
                                                            (in den ersten 12 Monaten)
                                                        </li>
                                                        <li>2.000 €
                                                            (in den ersten 24 Monaten)
                                                        </li>
                                                        <li>unbegrenzt
                                                            (ab dem 25. Monat)
                                                        </li>
                                                    </ul>
                                                </p>

                                            </div>
                                        </label>
                                    </div >
                                    <div className="col-lg-2 col-md-5 col-sm-10" >
                                        <label className="step-box-content bg-white text-center relative-position"
                                               style={{padding: 0, minHeight: 0}}>
                                            Vollschutz 100
                                            <div className="displayBoxRates">
                                                <p>Veterinärmedizinisch notwendige chirurgische Eingriffe 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box1/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Veterinärmedizinisch notwendige Heilbehandlungen 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box1/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Ausschließlich schmerzstillende Zahnbehandlungen inkl. Extraktion unter Narkose 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box2/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Operationsvorbereitende Untersuchungen 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box3/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Nachsorge nach dem chirurgischen Eingriff 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Unterbringung in einer Tierklinik oder Tierarztpraxis 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Arznei-, Verband-, Heil- und Hilfsmittel 100%
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>Gesundheitspauschale 100 €</p>
                                                <p>
                                                    Geltungsbereich:<br/>
                                                    <ul>
                                                        <li>Europa
                                                            <OverlayTrigger placement="top" overlay={renderPopover(<Box61/>)}>
                                                                <Button className="border-dark btn-light btn-circle" >i</Button>
                                                            </OverlayTrigger>
                                                        </li>
                                                        <li>Außerhalb Europas
                                                            <OverlayTrigger placement="top" overlay={renderPopover(<Box71/>)}>
                                                                <Button className="border-dark btn-light btn-circle" >i</Button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    Höchstsummen:
                                                    <OverlayTrigger placement="top" overlay={renderPopover(<Box81/>)}>
                                                        <Button className="border-dark btn-light btn-circle" >i</Button>
                                                    </OverlayTrigger>
                                                    <ul>
                                                        <li>1.300 €
                                                            (in den ersten 12 Monaten)
                                                        </li>
                                                        <li>2.600 €
                                                            (in den ersten 24 Monaten)
                                                        </li>
                                                        <li>unbegrenzt
                                                            (ab dem 25. Monat)
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span className="displayRates">
                                Versicherungsleistungen
                            </span>
                            <div className="step-box block-plan displayRates" style={{paddingTop: '20px'}}>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-10 ">
                                        <label
                                            className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            <p><span className="step-box-text" >Veterinärmedizinisch notwendige chirurgische Eingriffe</span>
                                                <OverlayTrigger placement="top" overlay={renderPopover(<Box1/>)}>
                                                    <Button className="border-dark btn-light btn-circle" >i</Button>
                                                </OverlayTrigger>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p ><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">60%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">80%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-10 ">
                                        <label
                                            className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            <p><span className="step-box-text" >Veterinärmedizinisch notwendige Heilbehandlungen</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p ><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">60%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">80%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-10 ">
                                        <label
                                            className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            <p><span className="step-box-text" >Ausschließlich schmerzstillende Zahnbehandlungen inkl. Extraktion unter Narkose</span>
                                                <OverlayTrigger placement="top" overlay={renderPopover(<Box2/>)}>
                                                    <Button className="border-dark btn-light btn-circle" >i</Button>
                                                </OverlayTrigger>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p ><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">60%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">80%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-10 ">
                                        <label
                                            className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            <p><span className="step-box-text" >Operationsvorbereitende Untersuchungen</span>
                                                <OverlayTrigger placement="top" overlay={renderPopover(<Box3/>)}>
                                                    <Button className="border-dark btn-light btn-circle" >i</Button>
                                                </OverlayTrigger>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p ><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">60%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">80%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-10 ">
                                        <label
                                            className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            <p><span className="step-box-text" >Nachsorge nach dem chirurgischen Eingriff</span>
                                                <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                    <Button className="border-dark btn-light btn-circle" >i</Button>
                                                </OverlayTrigger>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p ><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">60%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">80%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-10 ">
                                        <label
                                            className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            <p><span className="step-box-text" >Unterbringung in einer Tierklinik oder Tierarztpraxis</span>
                                                <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                    <Button className="border-dark btn-light btn-circle" >i</Button>
                                                </OverlayTrigger>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p ><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">60%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">80%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-10 ">
                                        <label
                                            className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            <p><span className="step-box-text" >Arznei-, Verband-, Heil- und Hilfsmittel </span>
                                                <OverlayTrigger placement="top" overlay={renderPopover(<Box4/>)}>
                                                    <Button className="border-dark btn-light btn-circle" >i</Button>
                                                </OverlayTrigger>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p ><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">60%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">80%</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p><span className="step-box-text">100%</span>
                                            </p>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-sm-10 ">
                                        <label
                                            className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            <p><span className="step-box-text" >Gesundheitspauschale</span>
                                                <OverlayTrigger placement="top" overlay={renderPopover(<Box5/>)}>
                                                    <Button className="border-dark btn-light btn-circle" >i</Button>
                                                </OverlayTrigger>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p ><span className="step-box-text">&times;</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">60 €</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">

                                            <p><span className="step-box-text">80 €</span>
                                            </p>
                                        </label>
                                    </div>
                                    <div className="col-lg-2 col-md-5 col-sm-10">
                                        <label className="bg-white text-center relative-position h-100 border-bottom border-2 border-dark d-block">
                                            {/*<span className="step-box-icon" style={{height: '93px'}}>
                        <img src={require("../assets/img/d2.png")} alt=""/>
                    </span>*/}
                                            <p><span className="step-box-text">100 €</span>
                                            </p>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* /.inner */}
                <div className="actions">
                    <ul>
                        <li className="disable" aria-disabled="true">
                                <span className="js-btn-next" title="NEXT">
                                    Backward <i className="fa fa-arrow-right"/>
                                </span>
                        </li>
                        <li>
                    <span className="js-btn-next" title="NEXT" onClick={(e) => {
                        /*console.log(formData);
                        setPage(page + 1);
                        setFormData(formData);
                        setX(1000);*/
                        routeChange(e, formData.service_name);
                    }}>
                        WEITER <i className="fa fa-arrow-right"/>
                    </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    );
};

export default SignUp2;
