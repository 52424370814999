//import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignUp from "./components/SignUp";
import SignUp1 from "./components/SignUp1";
import SignUp2 from "./components/SignUp2";
import PersonalInfo from "./components/PersonalInfo";
import LocationInfo from "./components/LocationInfo";
import OtherInfo from "./components/OtherInfo";
import ThankYou from "./components/ThankYou";

function App() {
  const [page, setPage] = useState(0);

  const [x, setX] = useState(0);

  const [formData, setFormData] = useState({
    service_name: "choice1"
  });

  const initSelectDataOptions = () => {
    var options = [];
    var months1 = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    for (var i = 1; i <= 12; i++) {
      let month = i, d = new Date();
      d.setMonth(d.getMonth() + month);
      options.push('01-' + months[d.getMonth()] + '-' + d.getFullYear());
    }
    //console.log(options);
    return options;
  }

  const Headline = ({ p }) => {

    useEffect(() => {
      let header = document.getElementsByClassName('progress_bar');

      if (p > 0){
        header[0].classList.remove('showonstep2');

        let btns = document.getElementsByClassName('multisteps-form__progress-btn');

        for (var i = 0; i < btns.length; i++) {
          if (i <= p - 1) {
            btns[i].classList.add('js-active');
          }
        }
      }
      else
        header[0].classList.add('showonstep2');
    });

    return <div className="row showonstep2 progress_bar">
        <div className="col-10 col-lg-10 ml-auto mr-auto mb-5 mt-5">

        </div>
      </div>;
  };

  const componentList = [
    <SignUp
      formData={formData}
      setFormData={setFormData}
      page={page}
      setPage={setPage}
      x={x}
      setX={setX}
    />,
   /* <PersonalInfo
      formData={formData}
      setFormData={setFormData}
      page={page}
      setPage={setPage}
      x={x}
      setX={setX}
      optiondata={initSelectDataOptions()}
    />,
    <LocationInfo
      formData={formData}
      setFormData={setFormData}
      page={page}
      setPage={setPage}
      x={x}
      setX={setX}
    />,
    <OtherInfo
      formData={formData}
      setFormData={setFormData}
      page={page}
      setPage={setPage}
      x={x}
      setX={setX}
    />,*/
    <ThankYou
    />
  ];
  return (
    <div className="App">
      <Headline p={page}/>
      {/*<div className="progress-bar">
        {
          <div
            style={{
              width:
                page === 0
                  ? "25%"
                  : page === 1
                  ? "50%"
                  : page === 2
                  ? "75%"
                  : "100%",
            }}
          ></div>
        }
      </div>*/}


        <div>


          <BrowserRouter>
            <Routes>
                <Route path="katzen" element={<SignUp
                    formData={formData}
                    setFormData={setFormData}
                    page={page}
                    setPage={setPage}
                    x={x}
                    setX={setX}
                />} />
                <Route path="hunde" element={<SignUp1
                    formData={formData}
                    setFormData={setFormData}
                    page={page}
                    setPage={setPage}
                    x={x}
                    setX={setX}
                />} />
              <Route path="haftpflicht" element={<SignUp2
                  formData={formData}
                  setFormData={setFormData}
                  page={page}
                  setPage={setPage}
                  x={x}
                  setX={setX}
              />} />
            </Routes>
          </BrowserRouter>

        </div>

    </div>
  );
}

export default App;
